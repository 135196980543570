import { NgModule } from '@angular/core';
import {CommonModule, NgIf} from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// component imports
import { AlertComponent } from './alert/alert.component';
import { BackArrowComponent } from './back-arrow/back-arrow.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LogoComponent } from './logo/logo.component';
import { UserEditListComponent } from './user-edit-list/user-edit-list.component';
import { UserSearchSelectComponent } from './user-search-select/user-search-select.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { FooterButtonComponent } from './footer-button/footer-button.component';
import { KeywordSelectComponent } from './keyword-select/keyword-select.component';
import { AvatarComponent } from './avatar/avatar.component';
import { UserHeightPipe } from './pipes/user-height.pipe';
import { QualificationAssignmentStatusPipe } from './pipes/qualification-assignment-status.pipe';
@NgModule({
  declarations: [
    AlertComponent,
    BackArrowComponent,
    LoginFormComponent,
    LogoComponent,
    UserEditListComponent,
    UserSearchSelectComponent,
    SearchBarComponent,
    LoadingButtonComponent,
    FooterButtonComponent,
    KeywordSelectComponent,
    AvatarComponent,
    UserHeightPipe,
    QualificationAssignmentStatusPipe,
  ],
    imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, NgIf],
  exports: [
    AlertComponent,
    BackArrowComponent,
    LoginFormComponent,
    LogoComponent,
    UserEditListComponent,
    UserSearchSelectComponent,
    SearchBarComponent,
    LoadingButtonComponent,
    FooterButtonComponent,
    KeywordSelectComponent,
    AvatarComponent,
    UserHeightPipe,
    QualificationAssignmentStatusPipe,
  ],
})
export class ComponentsModule {}
