import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';

import { SsoCallbackComponent } from './components/sso-callback/sso-callback.component';
import { OperationsModuleGuard } from './core/guards/operations-module.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToCheckLists = () => redirectLoggedInTo(['home']);
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToCheckLists },
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/logout/logout.module').then((m) => m.LogoutPageModule),
  },
  {
    path: 'all-checklists',
    loadChildren: () =>
      import('./pages/all-checklists/all-checklists.module').then(
        (m) => m.AllChecklistsPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'training/:id',
    loadChildren: () =>
      import('./pages/my-training/my-training.module').then(
        (m) => m.MyTrainingPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'checklist/:id',
    loadChildren: () =>
      import('./pages/checklist/checklist.module').then(
        (m) => m.ChecklistPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'trainee-checklist/:id',
    loadChildren: () =>
      import('./pages/trainee-checklist/trainee-checklist.module').then(
        (m) => m.TraineeChecklistPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'history',
    loadChildren: () =>
      import('./pages/my-training/history/history.module').then(
        (m) => m.HistoryPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'operations',
    loadChildren: () =>
      import('./pages/operations/operations.module').then(
        (m) => m.OperationsPageModule,
      ),
    canActivate: [AngularFireAuthGuard, OperationsModuleGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'safety-alerts',
    loadChildren: () =>
      import('./pages/safety-alerts/safety-alerts.module').then(
        (m) => m.SafetyAlertsModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'cards',
    loadChildren: () =>
      import('./pages/qualification-cards/qualification-cards.module').then(
        (m) => m.QualificationCardsModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'my-team',
    loadChildren: () =>
      import('./pages/my-team/my-team.module').then((m) => m.MyTeamModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'login/enterprise',
    loadChildren: () =>
      import('./pages/enterprise-login/enterprise-login.module').then(
        (m) => m.EnterpriseLoginPageModule,
      ),
  },
  {
    path: 'sso/kcs-secureauth',
    loadChildren: () =>
      import('./pages/sso/kcs2/kcs2.module').then((m) => m.Kcs2PageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToCheckLists },
  },
  // Aliases for KCS SSO in case old links may still be in use in their IT systems
  { path: 'sso/kcs', redirectTo: '/sso/kcs-secureauth', pathMatch: 'full' },
  { path: 'sso/kcs2', redirectTo: '/sso/kcs-secureauth', pathMatch: 'full' },

  {
    path: 'sso/auth0',
    loadChildren: () =>
      import('./pages/sso/auth0/auth0.module').then((m) => m.Auth0PageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToCheckLists },
  },
  {
    path: 'sso/bnsf',
    loadChildren: () =>
      import('./pages/sso/bnsf/bnsf.module').then((m) => m.BnsfPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToCheckLists },
  },
  {
    path: 'sso/cpkc',
    loadChildren: () =>
      import('./pages/sso/cpkc/cpkc.module').then((m) => m.CpkcPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToCheckLists },
  },
  {
    path: 'sso/conrail',
    loadChildren: () =>
      import('./pages/sso/conrail/conrail.module').then((m) => m.ConrailPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToCheckLists },
  },
  {
    path: 'sso/omnitrax',
    loadChildren: () =>
      import('./pages/sso/omnitrax/omnitrax.module').then((m) => m.OmnitraxPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToCheckLists },
  },
  {
    path: 'sso/ns',
    loadChildren: () =>
      import('./pages/sso/ns/ns.module').then((m) => m.NsPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToCheckLists },
  },
  { path: 'sso/callback/:token', component: SsoCallbackComponent },
  {
    path: 'unauthorized/:tenant-id',
    loadChildren: () =>
      import('./pages/unauthorized/unauthorized.module').then(
        (m) => m.UnauthorizedPageModule,
      ),
  },
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('./pages/unauthorized/unauthorized.module').then(
        (m) => m.UnauthorizedPageModule,
      ),
  },
  {
    path: 'bnsf',
    loadChildren: () =>
      import('./pages/sso/bnsf/bnsf.module').then((m) => m.BnsfPageModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'admin-app',
    loadChildren: () =>
      import('./pages/admin/admin.module').then((m) => m.AdminPageModule),
  },
  {
    path: 'enterprise-login',
    loadChildren: () =>
      import('./pages/enterprise-login/enterprise-login.module').then(
        (m) => m.EnterpriseLoginPageModule,
      ),
  },
  {
    path: 'kcs2',
    loadChildren: () =>
      import('./pages/sso/kcs2/kcs2.module').then((m) => m.Kcs2PageModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/logout/logout.module').then((m) => m.LogoutPageModule),
  },
  {
    path: 'trainee-search',
    loadChildren: () =>
      import('./pages/trainee-search/trainee-search.module').then(
        (m) => m.TraineeSearchPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'prefix',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
