export class DatePickerOptions {
  inputDate = new Date(); // default new Date()
  // showTodayButton: false, // default true
  closeOnSelect = true; // default false
  mondayFirst = true; // default false
  titleLabel = 'Select a Date'; // default null
  monthsList = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  weeksList = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  dateFormat = 'MM/DD/YYYY'; // default DD MMM YYYY
  clearButton = false; // default true
  momentLocale = 'en-US'; // Default 'en-US'
  btnCloseSetInReverse = true; // Default false
  btnProperties = {
    expand: 'block', // Default 'block'
    fill: '', // Default 'solid'
    size: '', // Default 'default'
    disabled: '', // Default false
    strong: '', // Default false
    color: '' // Default ''
  };
  fromDate?: Date;
  toDate?: Date;

  constructor(properties?: any) {
    Object.assign(this, properties);
  }
}
